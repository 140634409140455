<template>
    <!-- <v-list dense class="py-2">
      <v-list-item
        v-for="chat in chats"
        :key="chat.id"
        :class="{ 'highlighted-chat': chat === selectedChat }"
        @click="selectChat(chat)"
        class="my-1"
      >
        <v-list-item-content>
          <v-list-item-title>{{ chat.contact.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list> -->
    <v-list three-line>
          <v-list-item v-for="(chat, index) in chats" :key="index" :class="{ 'highlighted-chat': chat === selectedChat }"
          @click="selectChat(chat)"
          class="my-1"
          >
            <v-list-item-content>
              <v-list-item-title v-html="chat.contact.name"></v-list-item-title>
              <v-list-item-subtitle v-html="chat.contact.phoneNumber"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
    </v-list>
</template>
<script>
export default {
  props: ['chats'],
  data () {
    return {
      selectedChat: null
    }
  },
  methods: {
    selectChat (chat) {
      this.selectedChat = chat
      this.$emit('chat-selected', chat.contact.id)
    }
  }
}
</script>
<style scoped>
.highlighted-chat {
  background-color: #eee;
}
</style>
