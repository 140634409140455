<template>
      <v-row class="pa-10">
        <v-col cols="12" sm="6">
        <v-row>
          <v-col cols="12" sm="10">
            <h1 style="color: black;">Welcome to Lipachat!</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="10">
            <p>Lipachat provides seamless integration for WhatsApp using Facebook Login. The process is simple:</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" class="pl-5">
            <ol>
              <li>Click the 'Login with Facebook' button to authorize Lipachat to manage your WhatsApp Business Account.</li>
              <li>Allow access to the necessary permissions for Lipachat to function properly.</li>
              <li>Link your WhatsApp Business Account as instructed by the prompt.</li>
              <li>Upon successful linking, Lipachat will be able to provide WhatsApp integration services for your account.</li>
            </ol>
          </v-col>
        </v-row>
        </v-col>
        <v-col cols="12" sm="6">
          <button @click="launchWhatsAppSignup" class="login-button">Login with Facebook</button>
        </v-col>
      </v-row>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      accessToken: null
    }
  },
  methods: {
    launchWhatsAppSignup () {
      // eslint-disable-next-line
      FB.login((response) => {
        if (response.authResponse) {
          this.accessToken = response.authResponse.accessToken
          // Use this token to call the debug_token API and get the shared WABA's ID
          // You might want to call your API here
        } else {
          console.log('User cancelled login or did not fully authorize.')
        }
      }, {
        scope: 'whatsapp_business_management',
        extras: {
          feature: 'whatsapp_embedded_signup',
          setup: {}
        }
      })
    },
    callApi (phoneID, wabaID) {
      // Implement your API call here
      const url = 'https://gateway.lipachat.com/api/whatsapp/register/' + this.accessToken
      axios.get(url).then(response => {
        console.log(response.data)
      }).catch(error => {
        console.error(error)
      })
    },
    sessionInfoListener (event) {
      if (event.origin !== 'https://www.facebook.com') return
      try {
        const data = JSON.parse(event.data)
        console.log(data)
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            const { phoneID, wabaID } = data.data
            // Potentially call your API here
            this.callApi(phoneID, wabaID)
          } else {
            console.log(data.data)
          }
        }
      } catch {
        console.log('Non JSON Response', event.data)
      }
    }
  },
  mounted () {
    window.addEventListener('message', this.sessionInfoListener)
  },
  beforeUnmount () {
    window.removeEventListener('message', this.sessionInfoListener)
  }
}
</script>
<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 2em;
}

.info-container {
  text-align: left;
  max-width: 700px;
}
.instructions {
  margin-bottom: 2em;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.info-container h1 {
  font-size: 2em;
  color: #1877f2;
}

.login-button {
  background-color: #1877f2;
  border: 0;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  padding: 0 24px;
  min-width: 200px;
}
</style>
