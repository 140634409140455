<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            </v-col>
          </v-card-title>
          <v-data-table :headers="headers" :items="messages"  :search="search" hide-default-footer :loading="loading">
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ item.createdAt | formatdate }}
              </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Messages',
  data: () => ({
    search: '',
    headers: [
      { text: 'Type', value: 'type' },
      { text: 'Text', value: 'text' },
      { text: 'Phone Number', value: 'contact.phoneNumber' },
      { text: 'Direction', value: 'direction' },
      { text: 'Date Created', value: 'createdAt' }
    ],
    loading: false,
    messages: []
  }),
  created () {
    this.triggerGetMessages()
  },
  methods: {
    ...mapActions({ getMessages: 'messages/getMessages' }),
    triggerGetMessages () {
      this.loading = true
      this.messages = []
      this.getMessages().then(response => {
        this.messages = response.data.data
        this.loading = false
      }).catch(error => {
        this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
        this.loading = false
      })
    }
  }
}
</script>
