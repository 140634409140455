<template>
    <v-row class="chat-view d-flex flex-column">
      <v-card min-height="80vh" flat>
        <!-- <v-row>
        <v-col cols="12">
        <v-list two-line subheader>
        <v-list-item
          v-for="(message, i) in messages"
          :key="i"
          :class="{'align-right': message.direction === 'OUTBOUND'}"
        >
            <v-list-item-content class="pl-3 pr-3">
             <v-list-item-subtitle>{{ message.text }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
      </v-list>
      </v-col>
      </v-row> -->
      <v-row class="fill-height pb-14 ma-0">
        <v-col>
          <div v-for="(item, index) in messages" :key="index"
              :class="['d-flex flex-row align-center my-2', item.direction == 'OUTBOUND' ? 'justify-end': null]">
            <span v-if="item.direction == 'OUTBOUND'" class="white--text mr-3 message-span blue-background">{{ item.text }}</span>
            <span v-if="item.direction != 'OUTBOUND'" class="black--text ml-3 gray-background">{{ item.text }}</span>
          </div>
        </v-col>
      </v-row>
      </v-card>
      <v-card flat>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              label="Reply"
              single-line
              clearable
              filled
              rounded
              color="primary"
              v-model="newMessage"
              append-icon="mdi-send"
              @keyup.enter="triggerSendMessage"
              @click:append="triggerSendMessage"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-row>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: ['contactId'],
  data () {
    return {
      newMessage: '',
      messages: [],
      message: { contactId: 0, message: '' }
    }
  },
  methods: {
    ...mapActions({ getContactMessages: 'messages/getContactMessages', sendMessage: 'messages/sendMessage' }),
    triggerSendMessage () {
      if (!this.newMessage) return
      this.messages.push({ text: this.newMessage, direction: 'OUTBOUND' })
      this.message.contactId = this.contactId
      this.message.message = this.newMessage
      this.newMessage = ''
      this.sendMessage(this.message).then(response => {
        this.loading = false
        this.triggerGetContactMessages()
      }).catch(error => {
        this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
        this.loading = false
      })
    },
    triggerGetContactMessages () {
      this.loading = true
      this.messages = []
      this.getContactMessages(this.contactId).then(response => {
        this.messages = response.data.data
        this.loading = false
      }).catch(error => {
        this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
        this.loading = false
      })
    }
  },
  created () {
    this.triggerGetContactMessages()
  },
  watch: {
    contactId (newContactId) {
      this.triggerGetContactMessages()
    }
  }
}
</script>

<style scoped>
.align-right {
  text-align: right;
}
.chat-view {
  height: 100%;
}

.flex-grow-1 {
  flex-grow: 1;
}

.overflow-y-auto {
  overflow-y: auto;
}
.outbound-message {
  background-color: lightblue !important;
}
.message-span {
  max-width: 70%;
  display: inline-block;
}
.blue-background {
  background-color: blue;
  border-radius: 10px;
  padding: 10px;
}

.gray-background {
  background-color: #eeeee4;
  border-radius: 10px;
  padding: 10px;
}

</style>
