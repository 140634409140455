<template>
  <v-container fluid>
    <v-row class="grey lighten-3">
      <v-col cols="3">
        <ChatList :chats="chats" @chat-selected="selectedChat = $event" />
      </v-col>
      <v-col cols="9" class="pa-0">
        <v-sheet rounded="lg" style="overflow-y: auto;">
        <ChatView v-if="selectedChat" :contactId="selectedChat" />
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import ChatList from '../components/ChatList.vue'
import ChatView from '../components/ChatView.vue'
export default {
  name: 'Chats',
  components: {
    ChatList,
    ChatView
  },
  data: () => ({
    loading: true,
    chats: [],
    selectedChat: null
  }),
  created () {
    this.triggerGetChats()
  },
  methods: {
    ...mapActions({ getChats: 'chats/getChats' }),
    triggerGetChats () {
      this.loading = true
      this.chats = []
      this.getChats().then(response => {
        this.chats = response.data.data
        this.loading = false
      }).catch(error => {
        this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
        this.loading = false
      })
    }
  }
}
</script>
